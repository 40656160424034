





























































































































































import { Component, Vue } from 'vue-property-decorator'
import {
  deleteTicket,
  deleteTicketBulk,
  exportTickets,
  getTickets,
  getTicketsCount
} from '@/api/tickets'
import {
  createFilter,
  dateFilter,
  getSortItems,
  ignoreCaseSearchField,
  prepareListFilters,
  searchFieldConcat, searchFieldConcatReverse, singleSelectFilter,
  usersFilter
} from '@/utils/filter'
import Pagination from '@/components/Pagination/index.vue'
import ListToolbar from '@/components/ListToolbar/index.vue'
import FiltersDrawer from '@/components/Filter/FiltersDrawer.vue'
import {
  debounce,
  parseTime,
  getTranslations,
  hasPermission,
  confirmDialog,
  successMsg,
  errorMsg
} from '@/utils'
import { AppModule, DeviceType } from '@/store/modules/app'

@Component({
  name: 'CallList',
  components: {
    Pagination,
    ListToolbar,
    FiltersDrawer
  }
})

export default class extends Vue {
  private count = 0
  private total = 0
  private list = []
  private listLoading = true
  private showFilters = false
  private search = ''
  private selected: any = []
  private listQuery = createFilter()
  private debounceMethod = debounce(this.getList)
  private parseTime = parseTime
  private getTranslations = getTranslations
  private hasPermission = hasPermission

  private filters: any = [
    singleSelectFilter({
      lowerCase: true,
      label: 'filters.status',
      key: 'status',
      transKey: 'ticket_status',
      list: ['OPEN', 'CLOSED']
    }),
    singleSelectFilter({
      lowerCase: true,
      label: 'filters.severity',
      key: 'severity',
      transKey: 'ticket_severity',
      list: ['CRITICAL', 'MAJOR', 'MINOR']
    }),
    singleSelectFilter({
      lowerCase: true,
      label: 'filters.type',
      key: 'type',
      transKey: 'ticket_type',
      list: ['BUG', 'FEATURE']
    }),
    usersFilter(),
    dateFilter()
  ]

  created() {
    this.getList()
  }

  get isMobile() {
    return AppModule.device === DeviceType.Mobile
  }

  private async deleteItem(id: string) {
    try {
      const [data] = await confirmDialog('actions.apiDelete')
      if (!data) return
      await deleteTicket({ id })
      await successMsg('actions.apiDeleteSuccess')
      await this.getList()
    } catch (err) {
      await errorMsg('api.serverError')
    }
  }

  private handleSelectionChange(selected = []) {
    this.selected = selected.map((item: any) => {
      return item.id
    })
  }

  private handleSortChange(params: any = {}) {
    if (this.listQuery?.query?.sort) {
      this.listQuery.query.sort = getSortItems(params)
    }
    this.getList()
  }

  private submitFilters(filters: any) {
    this.filters = filters
    this.getList()
  }

  private async deleteItemsBulk() {
    if (!this.selected.length) return errorMsg('actions.selectItems')
    try {
      const [data] = await confirmDialog('actions.apiDeleteBulk')
      if (!data) return
      await deleteTicketBulk(this.selected)
      await successMsg('actions.apiDeleteBulkSuccess')
      await this.getList()
    } catch (err) {
      await errorMsg('api.serverError')
    }
  }

  private prepareFilters(filters: any) {
    prepareListFilters({
      listQuery: this.listQuery,
      withDefaultSort: true,
      search: this.search,
      searchFields: [
        searchFieldConcat('user.'),
        searchFieldConcatReverse('user.'),
        searchFieldConcat('client.'),
        searchFieldConcatReverse('client.'),
        ignoreCaseSearchField('property.full_code')
      ],
      filters: filters
    })
  }

  private async filtersChanged(filters: any) {
    try {
      this.prepareFilters(filters)
      const { data } = await getTicketsCount(this.listQuery)
      this.count = data || 0
    } catch (err) {}
  }

  private redirectToEdit(id: any) {
    return this.$router.push('/support/ticketing/edit/' + id)
  }

  private handleCommand(command: string) {
    switch (command) {
      case 'download': {
        exportTickets(this.list)
        break
      }
      case 'delete': {
        this.deleteItemsBulk()
        break
      }
      case 'filters': {
        this.count = this.total
        this.showFilters = true
        break
      }
    }
  }

  private async getList() {
    this.listLoading = true
    try {
      this.prepareFilters(this.filters)
      const { data } = await getTickets(this.listQuery)
      this.list = data.collection || []
      this.total = data.pagination.total_items || 0
      this.count = data.pagination.total_items || 0
    } catch (err) {}
    this.listLoading = false
  }

  onSearchChanged(search: string) {
    this.search = search
    if (this.listQuery.query?.pagination) {
      this.listQuery.query.pagination.page = 1
    }
    this.debounceMethod()
  }
}
